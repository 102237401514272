<template>
  <BodymassTable :studentId="studentId" />
</template>

<script>
import BodymassTable from './BodymassTable.vue';
export default {
  name: 'BodymassAndAttendancePage',
  props: { studentId: Number },
  components: { BodymassTable },
  setup() {},
};
</script>
