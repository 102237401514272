<template>
  <div class="page">
    <div class="sub-page">
      <div class="page-top-title">
        เวลามาเรียน (คิดเป็นวัน)
      </div>
      <div>
        <table width="100%">
          <thead>
            <tr>
              <td>ภาคเรียน / เวลาเรียน</td>
              <td>เวลาเรียนเต็ม</td>
              <td>มาเรียน</td>
              <td>ไม่มาเรียน</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(attendance, index) in attendanceDataSource" :key="index">
              <td>{{ attendance.title }}</td>
              <td>{{ attendance.total }}</td>
              <td>{{ attendance.present }}</td>
              <td>{{ attendance.absent }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';

const nullTerm = termNo => {
  return {
    title: `ภาคเรียนที่ ${termNo}`,
    total: 0,
    present: 0,
    absent: 0,
    leave: 0,
    sick: 0,
  };
};

export default {
  name: 'BodymassAndAttendancePage',
  props: { studentId: Number },
  setup(props) {
    const { state, getters, dispatch } = useStore();
    const student = computed(() => getters.getStudentById(props.studentId));
    const attendanceReports = computed(() => state.print.attendanceReport);

    const schoolYear = computed(() =>
      state.schoolYear.data.find(schoolYear => schoolYear.id == student.value.schoolYearId),
    );

    const term1 = computed(() => schoolYear.value.terms.find(term => term.termNo == 1));
    const term2 = computed(() => schoolYear.value.terms.find(term => term.termNo == 2));

    const attendanceDataSource = computed(() => {
      if (!attendanceReports.value) {
        return [nullTerm(1), nullTerm(2)];
      }

      const found = attendanceReports.value.find(item => item.studentId == props.studentId);
      if (!found) return [nullTerm(1), nullTerm(2)];
      const { termly, yearly } = found;

      const term1Data = termly.find(termItem => termItem.termId == term1.value.id) ?? nullTerm(1);
      const term2Data = termly.find(termItem => termItem.termId == term2.value.id) ?? nullTerm(2);

      return [
        {
          title: 'ภาคเรียนที่ 1',
          total: term1Data.total,
          present: term1Data.present,
          absent: Number(term1Data.sick) + Number(term1Data.absent) + Number(term1Data.leave),
        },
        {
          title: 'ภาคเรียนที่ 2',
          total: term2Data.total,
          present: term2Data.present,
          absent: Number(term2Data.sick) + Number(term2Data.absent) + Number(term2Data.leave),
        },
        {
          title: 'ตลอดปี',
          total: yearly.total,
          present: yearly.present,
          absent: Number(yearly.sick) + Number(yearly.absent) + Number(yearly.leave),
        },
      ];
    });

    onMounted(async () => {
      dispatch('getAttendanceReport', { classroomId: student.value.classroomId, 'studentIds[]': student.value.id });
    });

    return { attendanceDataSource };
  },
};
</script>
