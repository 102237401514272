<template>
  <AttendacnceTable :studentId="studentId" />
</template>

<script>
import AttendacnceTable from './AttendacnceTable.vue';
export default {
  name: 'BodymassAndAttendancePage',
  props: { studentId: Number },
  components: { AttendacnceTable },
  setup() {},
};
</script>
