<template>
  <div class="page">
    <div class="sub-page">
      <div class="page-top-title">
        บันทึกพัฒนาการด้านร่างกาย
      </div>
      <div>
        <table width="100%">
          <thead>
            <tr>
              <td rowspan="2" width="28%">รายการ</td>
              <td colspan="4">ภาคเรียนที่ 1</td>
              <td colspan="4">ภาคเรียนที่ 2</td>
            </tr>
            <tr>
              <td width="9%">ครั้งที่ 1</td>
              <td width="9%">ระดับคุณภาพ</td>
              <td width="9%">ครั้งที่ 2</td>
              <td width="9%">ระดับคุณภาพ</td>
              <td width="9%">ครั้งที่ 1</td>
              <td width="9%">ระดับคุณภาพ</td>
              <td width="9%">ครั้งที่ 2</td>
              <td width="9%">ระดับคุณภาพ</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in bodymassDataSource" :key="index">
              <td v-for="(col, cIndex) in row" :key="`c${cIndex}`">
                {{ col }}
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <span>หมายเหตุ</span>
          <ul>
            <li>ระดับคุณภาพ 3 หมายถึง ปกติ / เป็นไปตามเกณฑ์มาตรฐาน</li>
            <li>ระดับคุณภาพ 2 หมายถึง ค่อนข้างปกติ / ค่อนข้างมาก - สูง หรือค่อนข้างน้อยกว่าเกณฑ์มาตรฐาน</li>
            <li>ระดับคุณภาพ 1 หมายถึง ไม่ปกติ ควรส่งเสริม / มาก - สูง หรือ น้่อยกว่าเกณฑ์มาตรฐาน</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import { weightToScore, heightToScore } from '@/utility/bodymassCalculator';

const getWeightRecord = (termId, evaluateNo, records) => {
  const found = records.find(rec => rec.schoolTermId == termId && rec.evaluateNo == evaluateNo);
  if (!found) return 0;
  return found.weight;
};

const getWeightScore = (termId, evaluateNo, records, student) => {
  const weight = getWeightRecord(termId, evaluateNo, records);
  return weightToScore(weight, student);
};

const getHeightRecord = (termId, evaluateNo, records) => {
  const found = records.find(rec => rec.schoolTermId == termId && rec.evaluateNo == evaluateNo);
  if (!found) return 0;
  return found.height;
};

const getHeightScore = (termId, evaluateNo, records, student) => {
  const height = getHeightRecord(termId, evaluateNo, records);
  return heightToScore(height, student);
};

export default {
  name: 'BodymassAndAttendancePage',
  props: { studentId: Number },
  setup(props) {
    const { state, getters, dispatch } = useStore();
    const student = computed(() => getters.getStudentById(props.studentId));
    const bodymassRecords = computed(() => state.bodymass.currentRecords);

    const schoolYear = computed(() =>
      state.schoolYear.data.find(schoolYear => schoolYear.id == student.value.schoolYearId),
    );

    const term1 = computed(() => schoolYear.value.terms.find(term => term.termNo == 1));
    const term2 = computed(() => schoolYear.value.terms.find(term => term.termNo == 2));

    onMounted(async () => {
      dispatch('getAttendanceReport', { classroomId: student.value.classroomId, 'studentIds[]': student.value.id });
    });

    const bodymassDataSource = computed(() => {
      if (!bodymassRecords.value) return [];

      const records = bodymassRecords.value.filter(record => record.studentId == props.studentId);

      const weights = [
        'น้ำหนัก (กก.)',
        getWeightRecord(term1.value.id, 1, records),
        getWeightScore(term1.value.id, 1, records, student.value),
        getWeightRecord(term1.value.id, 2, records),
        getWeightScore(term1.value.id, 2, records, student.value),
        getWeightRecord(term2.value.id, 1, records),
        getWeightScore(term2.value.id, 1, records, student.value),
        getWeightRecord(term2.value.id, 2, records),
        getWeightScore(term2.value.id, 2, records, student.value),
      ];

      const heights = [
        'ส่วนสูง (ซม.)',
        getHeightRecord(term1.value.id, 1, records),
        getHeightScore(term1.value.id, 1, records, student.value),
        getHeightRecord(term1.value.id, 2, records),
        getHeightScore(term1.value.id, 2, records, student.value),
        getHeightRecord(term2.value.id, 1, records),
        getHeightScore(term2.value.id, 1, records, student.value),
        getHeightRecord(term2.value.id, 2, records),
        getHeightScore(term2.value.id, 2, records, student.value),
      ];

      return [weights, heights];
    });

    return { bodymassDataSource };
  },
};
</script>
