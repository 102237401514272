<template>
  <a-spin :spinning="isLoadingDelete">
    <a-row justify="center">
      <a-col :xl="10" :md="16" :xs="24">
        <div class="user-info-form">
          <BasicFormWrapper>
            <a-form style="width: 100%;" name="info" :model="formState" :rules="rules">
              <a-form-item label="ชื่อ" name="firstname">
                <a-input v-model:value="formState.firstname" placeholder="ชื่อ" />
              </a-form-item>

              <a-form-item label="นามสกุล" name="lastname">
                <a-input v-model:value="formState.lastname" placeholder="นามสกุล" />
              </a-form-item>

              <a-form-item label="ชื่อกลาง" name="middlename">
                <a-input v-model:value="formState.middlename" placeholder="ชื่อกลาง (ไม่จำเป็น)" />
              </a-form-item>

              <a-form-item label="ชื่อเล่น" name="nickname">
                <a-input v-model:value="formState.nickname" placeholder="ชื่อเล่น (ไม่จำเป็น)" />
              </a-form-item>

              <a-form-item label="เพศ" name="gender">
                <a-select v-model:value="formState.gender" style="width: 100%">
                  <a-select-option value="MALE">ชาย</a-select-option>
                  <a-select-option value="FEMALE">หญิง</a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item label="เลขที่" name="studentNo">
                <a-input-number v-model:value="formState.studentNo" placeholder="เลขที่" />
              </a-form-item>

              <a-form-item label="รหัสประจำตัวนักเรียน" name="studentCode">
                <a-input v-model:value="formState.studentCode" placeholder="รหัสประจำตัวนักเรียน" />
              </a-form-item>

              <a-form-item label="วันเกิด" name="dateOfBirth">
                <a-datePicker
                  v-model:value="formState.dateOfBirth"
                  :format="dateFormat"
                  style="width: 100%"
                  placeholder="เลือกวันเกิด"
                  @change="validateDateOfBirth"
                />
              </a-form-item>

              <a-form-item label="สถานะ" name="status">
                <a-select style="width: 100%">
                  <a-select-option value="ACTIVE">ปกติ</a-select-option>
                  <a-select-option value="INACTIVE">ลาออก</a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item label="วันที่เข้าศึกษา" name="joinedAt">
                <a-datePicker
                  v-model:value="formState.joinedAt"
                  :format="dateFormat"
                  style="width: 100%"
                  placeholder="วันที่เข้าเรียน"
                />
              </a-form-item>

              <a-form-item label="วันที่ออก" name="leaveAt">
                <a-datePicker :format="dateFormat" style="width: 100%" placeholder="วันที่ออก" />
              </a-form-item>

              <a-form-item>
                <div class="add-user-bottom text-right">
                  <sdButton v-if="!isCreate" htmlType="submit" type="danger">
                    <a-popconfirm
                      title="คุณต้องการลบนักเรียนคนนี้ใช่ไหม?"
                      ok-text="Yes"
                      cancel-text="No"
                      @confirm="onRemove"
                      @cancel="cancel"
                    >
                      <a href="#">
                        <span>
                          ลบ
                        </span>
                      </a>
                    </a-popconfirm>
                  </sdButton>

                  <a-popconfirm
                    v-if="!isValidate"
                    title="อายุของไม่ตรงกับระดับชั้นคุณต้องการบันทึกหรือไม่"
                    ok-text="บันทึก"
                    cancel-text="ยกเลิก"
                    @confirm="onSubmit"
                    ><sdButton htmlType="submit" type="primary">
                      บันทึก
                    </sdButton>
                  </a-popconfirm>
                  <sdButton v-else htmlType="submit" type="primary" @click="onSubmit">
                    บันทึก
                  </sdButton>
                </div>
              </a-form-item>
            </a-form>
          </BasicFormWrapper>
        </div>
      </a-col>
    </a-row>
    <a-row>
      <div class="sDash_grade-confirm-block" v-if="false">
        <sdCards>
          <template #title>
            <div class="confirm-grade-card-title">
              <sdHeading as="h4">สรุปผลการประเมินพัฒนาการ</sdHeading>
              <span>ตามคุณลักษณะที่พึงประสงค์การศึกษาปฐมวัย ({{ classroom.gradeLevel }})</span>
            </div>
          </template>
          <ConfirmGradeListWrapper>
            <a-row :gutter="15">
              <a-col :xs="24" v-for="topic in topicList" :key="`tp${topic.id}`">
                <div class="notification-box-single">
                  <sdCards headless :bodyStyle="{ backgroundColor: '#F7F8FA', borderRadius: 10 }">
                    <div
                      :style="{
                        height: '50px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }"
                      class="notification-header"
                    >
                      <sdHeading class="notification-header__text" as="h4">
                        {{ topic.title }}
                      </sdHeading>
                    </div>
                    <div class="notification-body">
                      <sdCards headless>
                        <nav>
                          <ul
                            :style="{
                              margin: 0,
                              padding: 0,
                            }"
                          >
                            <li v-for="attribute in topic.attributes" :key="`attr${attribute.id}`">
                              <a-row justify="space-between" :gutter="8">
                                <a-col :xs="24" :md="18">
                                  <div class="notification-list-single">
                                    <sdHeading class="notification-list-single__title" as="h4">
                                      มาตรฐานที่ {{ attribute.order }}
                                    </sdHeading>
                                    <p>
                                      {{ attribute.title }}
                                    </p>
                                  </div>
                                </a-col>
                                <a-col :xs="24" :md="3" v-for="term in terms" :key="`tm${term.id}`">
                                  <div :style="{ 'text-align': 'right' }">
                                    <span :style="{ 'margin-right': '10px' }">ภาคเรียน {{ term.termNo }}</span>
                                    <a-avatar
                                      shape="square"
                                      size="small"
                                      :style="
                                        gradeAvatarStyle(getAttributeScoreWithTerm(term.id, attribute.id, topic.id))
                                      "
                                    >
                                      {{
                                        getAttributeScoreWithTerm(term.id, attribute.id, topic.id)
                                          ? getAttributeScoreWithTerm(term.id, attribute.id, topic.id)
                                          : '-'
                                      }}
                                    </a-avatar>
                                  </div>
                                </a-col>
                              </a-row>
                            </li>
                          </ul>
                        </nav>
                      </sdCards>
                    </div>
                    <div
                      :style="{
                        'margin-top': '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }"
                    >
                      <!-- <div>{{ topic.finalTopicScore ? topic.finalTopicScore : 'ไม่ระบุ' }}</div> -->
                      <div>สรุปตลอดปีการศึกษา {{ topic.finalTopicScore ?? 'ไม่ระบุ' }}</div>
                      <!-- <a-radio-group v-model:value="topic.finalTopicScore" button-style="solid" :disabled="unsaveable">
                      <a-radio-button :value="3">ดี</a-radio-button>
                      <a-radio-button :value="2">พอใช้</a-radio-button>
                      <a-radio-button :value="1">ความส่งเสริม</a-radio-button>
                    </a-radio-group> -->
                    </div>
                  </sdCards>
                </div>
              </a-col>
            </a-row>
          </ConfirmGradeListWrapper>
        </sdCards>
      </div>
    </a-row>
  </a-spin>
</template>

<style>
* {
  box-sizing: border-box;
}

/* Create three equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  padding: 10px;
  height: 200px; /* Should be removed. Only for demonstration */
}
.column2 {
  float: left;
  width: 50%;
  padding: 10px;
  height: 200px; /* Should be removed. Only for demonstration */
}
/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}
</style>
<script>
import { ConfirmGradeListWrapper, gradeAvatarStyle } from '../../gradeConfirmation/style';

import { BasicFormWrapper } from '../../../styled';
import { reactive, ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import { notification } from 'ant-design-vue';
import { useRouter } from 'vue-router';

import { matchAgeRange, getAgeString } from '@/components/utilities/utilities';

const Info = {
  name: 'Info',
  components: { BasicFormWrapper },
  props: {
    schoolYearId: Number,
    classroomId: Number,
    studentId: Number,
  },
  setup(props) {
    const router = useRouter();
    const { state, dispatch, getters } = useStore();
    const values = ref('');
    let isCreate = ref(false);
    let formState = reactive({
      firstname: '',
      lastname: '',
      middlename: '',
      nickname: '',
      gender: '',
      dateOfBirth: undefined,
      studentCode: undefined,
      studentNo: undefined,
      status: 'ACTIVE',
      joinedAt: undefined,
      leaveDate: undefined,
    });

    const schoolYear = computed(() => state.schoolYear.selected);
    const student = computed(() => state.students.selected);
    const students = computed(() => state.students.data);
    const isLoadingDelete = computed(() => state.students.loading);

    const classroomId = props.classroomId;
    let studentId = props.studentId;
    const schoolYearId = props.schoolYearId;
    const classroom = computed(() => {
      if (!state.classroom.classrooms) return null;
      const found = state.classroom.classrooms.find(item => item.id == classroomId);
      if (!found) return null;
      return found;
    });
    const isValidate = ref(false);

    if (studentId == undefined || studentId == 'create') {
      studentId = undefined;
      isCreate.value = true;
      isValidate.value = false;
    } else {
      isCreate.value = false;
    }

    const validateDateOfBirth = date => {
      console.log({ date: date });
      isValidate.value = matchAgeRange(date, selectedClassroom.value.gradeLevel);
    };

    const onRemove = async () => {
      if (isCreate.value) {
        return;
      }
      await dispatch('deleteStudent', {
        studentId: studentId,
        schoolYearId: schoolYearId,
        classroomId: classroomId,
      });

      const classrooms = await dispatch('forceGetAllClassrooms', { schoolYearId });
      dispatch('updateStudentTable', classrooms);

      notification.success({
        message: 'ลบสำเร็จ',
      });
      router.push(`/schoolYears/${schoolYearId}/classrooms/${classroomId}/`);
    };

    const onSubmit = async () => {
      if (formState.firstname.length == 0) {
        notification.warning({
          message: 'กรุณากรอกชื่อ',
        });
        return;
      }

      if (formState.lastname.length == 0) {
        notification.warning({
          message: 'กรุณากรอกนามสกุล',
        });
        return;
      }

      if (formState.gender.length == 0) {
        notification.warning({
          message: 'กรุณาระบุเพศของนักเรียน',
        });
        return;
      }

      if (formState.dateOfBirth == undefined || formState.dateOfBirth.length == 0) {
        notification.warning({
          message: 'กรุณากรอกวันเกิดของนักเรียน',
        });
        return;
      }

      if (formState.studentCode == undefined || formState.studentCode.length == 0) {
        notification.warning({
          message: 'กรุณากรอกรหัสประจำตัวนักเรียน',
        });
        return;
      }

      if (formState.studentNo == undefined || formState.studentNo.length == 0) {
        notification.warning({
          message: 'กรุณากรอกเลขที่นักเรียน',
        });
        return;
      }

      if (formState.joinedAt == undefined || formState.joinedAt.length == 0) {
        notification.warning({
          message: 'กรุณากรอกวันที่เข้าศึกษา',
        });
        return;
      }

      if (isCreate.value) {
        console.log('create');
        notification.info({
          message: 'กำลังบันทึกข้อมูล',
        });
        await dispatch('createStudent', { student: formState, schoolYearId: schoolYear.value.id, classroomId });
        notification.success({
          message: 'บันทึกข้อมูลสำเร็จ',
        });
        router.push(`/schoolYears/${schoolYearId}/classrooms/${classroomId}/`);
        const classrooms = await dispatch('forceGetAllClassrooms', { schoolYearId });
        dispatch('updateStudentTable', classrooms);
      } else {
        notification.info({
          message: 'กำลังอัพเดทข้อมูล',
        });
        await dispatch('updateStudent', {
          studentId: studentId,
          student: formState,
          schoolYearId: schoolYear.value.id,
          classroomId,
        });
        notification.success({
          message: 'อัพเดทข้อมูลสำเร็จ',
        });
        router.push(`/schoolYears/${schoolYearId}/classrooms/${classroomId}/`);
        const classrooms = await dispatch('forceGetAllClassrooms', { schoolYearId });
        dispatch('updateStudentTable', classrooms);
      }
    };

    const rules = {};
    watch(
      () => state.students.selected,
      student => {
        console.log('submit!', student);
        if (student) {
          formState.firstname = student.firstname;
          formState.lastname = student.lastname;
          formState.gender = student.gender;
          formState.middlename = student.middlename ? student.middlename : '-';
          formState.nickname = student.nickname ? student.nickname : '-';
          formState.dateOfBirth = moment(student.dateOfBirth);
          formState.studentCode = student.studentCode;
          formState.studentNo = student.studentNo;
          formState.joinedAt = student.joinedAt;
          formState.leaveDate = student.leaveDate;

          isValidate.value = matchAgeRange(formState.dateOfBirth, selectedClassroom.value.gradeLevel);
        }
      },
    );

    onMounted(async () => {
      console.log('onMounted');

      if (studentId) {
        dispatch('getStudentInfoLocal', studentId);
      }

      const found = classrooms.value.find(classroom => classroom.id == classroomId);
      selectedClassroom.value = found;

      if (!topicList.value) {
        await fetchTopicsList();
      }

      await fetchStudentAttributeScore();
      await fetchStudentGrades();

      topicList.value.forEach(topic => {
        if (!topicGrades.value) {
          topic.finalTopicScore = presetFinalTopicScore(topic.id);
        } else {
          topic.finalTopicScore = getFinalTopicScore(topic.id);
        }
      });
    });

    const classrooms = computed(() => state.classroom.classrooms);
    const topicList = computed(() => state.evaluateForm.currentForm);
    const attrScores = computed(() => state.gradeConfirmation.currentRecords);
    const topicGrades = computed(() => state.gradeConfirmation.currentTopicGradeRecords);
    const terms = computed(() => getters.selectedYearTerms());
    const classroomLoading = computed(() => state.classroom.loading);
    const formLoading = computed(() => state.evaluateForm.loading);
    const gradeLoading = computed(() => state.gradeConfirmation.loading);
    const isLoading = computed(() => classroomLoading.value || formLoading.value || gradeLoading.value);

    const unsaveable = computed(() => {
      if (!topicGrades.value) {
        return false;
      }
      return true;
    });

    const selectedClassroom = ref(null);
    const getFinalTopicScore = topicId => {
      if (!topicGrades.value) return null;

      const foundGrade = topicGrades.value.find(grade => {
        return grade.developmentTopicId == topicId;
      });

      if (!foundGrade) return null;

      return foundGrade.score;
    };

    const presetFinalTopicScore = topicId => {
      if (!attrScores.value) {
        return null;
      }

      const studentkey = `student_${student.value.id}`;
      const topicKey = `topic_${topicId}`;
      const topic = attrScores.value[studentkey][topicKey];

      const lastTermId = terms.value.find(term => term.termNo == 2).id;

      const attributes = Object.values(topic);
      const attributeSummation = attributes.reduce((acc, attribute) => {
        acc = acc + attribute[`term_${lastTermId}`];
        return acc;
      }, 0);

      const result = attributeSummation / attributes.length;

      const score = Math.round(result);

      return score;
    };

    const fetchTopicsList = () => {
      return new Promise((resolve, reject) => {
        dispatch('getEvaluateForm', {
          standardYear: schoolYear.value.standardYear,
          gradeLevel: selectedClassroom.value.gradeLevel,
        })
          .then(val => {
            resolve(val);
            console.log({ topicList: val });
          })
          .catch(err => {
            reject(err);
          });
      });
    };

    const fetchStudentAttributeScore = () => {
      if (student.value == null || student.value == undefined) {
        return;
      }
      return new Promise((resolve, reject) => {
        dispatch('getAttributeScores', {
          schoolYearId: props.schoolYearId,
          classroomId: selectedClassroom.value.id,
          studentId: student.value.id,
        })
          .then(val => {
            resolve(val);
          })
          .catch(err => {
            reject(err);
          });
      });
    };

    const fetchStudentGrades = () => {
      if (student.value == null || student.value == undefined) {
        return;
      }
      return new Promise((resolve, reject) => {
        dispatch('getTopicGrade', {
          schoolYearId: props.schoolYearId,
          classroomId: selectedClassroom.value.id,
          studentId: student.value.id,
        })
          .then(val => {
            resolve(val);
          })
          .catch(err => {
            reject(err);
          });
      });
    };

    const getAttributeScoreWithTerm = (termId, attrId, topicId) => {
      if (!attrScores.value) {
        return;
      }

      const studentkey = `student_${student.value.id}`;
      const topicKey = `topic_${topicId}`;
      const attributeKey = `attribute_${attrId}`;
      const termKey = `term_${termId}`;
      const score = attrScores.value[studentkey][topicKey][attributeKey][termKey];
      return score;
    };

    return {
      values,
      formState,
      rules,
      onSubmit,
      isCreate,
      student,
      schoolYearId,
      fetchTopicsList,
      fetchStudentAttributeScore,
      fetchStudentGrades,
      topicList,
      attrScores,
      topicGrades,
      terms,
      getAttributeScoreWithTerm,
      isLoading,
      unsaveable,
      students,
      classroom,
      ConfirmGradeListWrapper,
      gradeAvatarStyle,
      onRemove,
      isLoadingDelete,
      matchAgeRange,
      getAgeString,
      validateDateOfBirth,
      isValidate,
    };
  },
};

export default Info;
</script>
